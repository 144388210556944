<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable" v-loading="loading">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<!-- <el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button> -->
									<span class="tct_tit">{{$t('i18nn_a0030be92f6b1c1a')}}</span>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">

								<el-button type="primary" icon="el-icon-plus" size="small" @click="addData()">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="">
							<div class="filterBarList">
								<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
									<el-menu-item :index="item.value" v-for="item in selectOption.statusList"
										:key="item.value">{{item.label}}</el-menu-item>
								</el-menu>
							</div>
							
							<ul class="filterConList">
								<!-- <li>
									<span>{{$t('hytxs0000060')}}</span>
									<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
								</li> -->
								<li>
									<span>{{ $t('c944a6686d996ab3') }}</span>
									<whNoSelect @changeData="changWhNo"></whNoSelect>
								</li>
								<li>
									<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
									<el-input type="text" v-model="filterData.keyword" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('custom_enterfuzzysearch')"
										style="width: 180px;" />
								</li>
								<li>
									<el-button icon="el-icon-search" size="small" type="primary"
										@click="initData()">{{ $t('1e7246dd6ccc5539') }}</el-button>
								</li>
							</ul>
						</div>
					</div>

					<el-table id="" ref="multipleTable" :data="tableData" stripe :border="true"
						:max-height="$store.state.tableMaxHeight5" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" style="width: 100%" size="small">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>

						<el-table-column prop="whNo" :label="$t('c944a6686d996ab3')"></el-table-column>

						<el-table-column prop="taskNo" :label="$t('i18nn_44858bef4ea92a98')" ></el-table-column>

						<el-table-column prop="title" :label="$t('i18nn_5c5140f8913c5819')" ></el-table-column>
						<el-table-column prop="bckAccount" :label="$t('i18nn_1bbf0464d7c1ed75')" ></el-table-column>

						<el-table-column prop="statusName" :label="$t('6cdece641436d7ab')" >
							<template slot-scope="scope">
								<div>
									<el-tag type="success" v-if="'10' == scope.row.status">{{scope.row.statusName}}</el-tag>
									<el-tag type="primary"
										v-else-if="'40' == scope.row.status">{{scope.row.statusName}}</el-tag>
									<el-tag type="warning"
										v-else-if="'50' == scope.row.status">{{scope.row.statusName}}</el-tag>
									<el-tag type="danger"
										v-else-if="'60' == scope.row.status">{{scope.row.statusName}}</el-tag>
									<el-tag type="info" v-else>{{scope.row.statusName}}</el-tag>
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="taskType" :label="'任务类型'" ></el-table-column> -->
						<el-table-column prop="invTypeName" :label="'盘点类型'" ></el-table-column>

						<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column>

						<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>

						<el-table-column :label="$t('93f5ca01b006206c')" width="300px" fixed="right">
							<template slot-scope="scope">
								<div>
									<div style="margin-bottom: 5px;">
										<el-button type="primary" icon="el-icon-view" size="small"
											@click="viewInvInfoData($event,scope.row)">{{$t('i18nn_51521d7e394e5b44')}}</el-button>
										
										
									</div>
									
									<div>
										<el-button type="success" icon="el-icon-check" size="small"
											@click="FinishTask($event,scope.row)" v-if="'40'==scope.row.status">确认完成</el-button>
										
										<el-button type="danger" icon="el-icon-close" size="small"
											@click="CancelTask($event,scope.row)" v-if="'50'==scope.row.status">撤销确认</el-button>
										
											
										<el-button type="warning" icon="el-icon-finished" size="small"
											@click="SyncSysStockAction($event,scope.row)" v-if="'50'==scope.row.status">{{$t('i18nn_68624892c0eb7fb8')}}</el-button>
										
									</div>
									

								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="tableConPagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</div>
		<!-- 新增 -->
		<TakingInventoryTaskAdd :openTime="TakingInventoryTaskAddOpenTime" :row="TakingInventoryTaskAddRow" @success="successBack">
		</TakingInventoryTaskAdd>
		<!-- 全部盘库信息 -->
		<TakingInventoryQueryInfo :openTime="TakingInventoryQueryInfoOpenTime" :row="TakingInventoryQueryInfoRow" @success="successBack">
		</TakingInventoryQueryInfo>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	// import WhGoods from './WhGoods.vue';
	// import WhPlace from '@/components/WarehouseCenter/WhPlace.vue';
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	import TakingInventoryTaskAdd from '@/components/WarehouseCenter2/TakingInventory/TakingInventoryTaskAdd.vue';

	import TakingInventoryQueryInfo from '@/components/WarehouseCenter2/TakingInventory/TakingInventoryQueryInfo.vue';

	export default {
		components: {
			// WhPlace,
			// cusSelFuzzy,
			whNoSelect,
			TakingInventoryTaskAdd,
			TakingInventoryQueryInfo
		},
		data() {
			return {
				TakingInventoryTaskAddOpenTime: '',
				TakingInventoryTaskAddRow: {},

				TakingInventoryQueryInfoOpenTime: '',
				TakingInventoryQueryInfoRow: {},

				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),

				selectOption: {
					wh_goods_fee_type: [],
					statusList: [{
							value: '',
							label: this.$t('16853bda54120bf1')
						},
						{
							value: '10',
							label: '创建'
						},
						{
							value: '40',
							label: '盘库中'
						},
						{
							value: '50',
							label: '盘库确认完成'
						},
						{
							value: '60',
							label: '同步系统库存'
						}
					]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					
					status:"",
					packingNo: '',
					userId: '',
					keyword: "",
					whNo: '',
					orderNumber: ''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			//数据字典
			getDicData(['wh_goods_fee_type'],
				(data) => {
					this.selectOption.wh_goods_fee_type = data['wh_goods_fee_type'];
				});
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.tableData = [];
				this.getPageData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
				// this.getDicData();
			},
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			// changCus(data) {
			// 	console.log("changCus", data);
			// 	// this.filterData.cusNo = data.cusNo;
			// 	this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhInvTaskPageList, {
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目编号

						status: this.filterData.status ? this.filterData.status : null,
						// cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
						// userId: this.filterData.userId ? this.filterData.userId : null,
						whNo: this.filterData.whNo ? this.filterData.whNo : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// returnWhNo: this.filterData.whNo ? this.filterData.whNo : null,
						// orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
						// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					})
					.then(({
						data
					}) => {

						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
			//新增
			addData() {
				this.TakingInventoryTaskAddRow = {};
				this.TakingInventoryTaskAddOpenTime = new Date().getTime();
			},
			successBack(){
				this.initData();
			},

			//查看盘库信息
			viewInvInfoData(event, row) {
				this.TakingInventoryQueryInfoRow = row;
				this.TakingInventoryQueryInfoOpenTime = new Date().getTime();
			},

			//完成盘点任务
			FinishTask(event, row) {
				// this.TakingInventoryQueryInfoRow = row;
				// this.TakingInventoryQueryInfoOpenTime = new Date().getTime();
				this.$confirm('确定确认本次盘点任务吗?', this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhInvTaskComplete + "/" + row.id, {}, data => {
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							// this.dialogShow = false;
							this.initData();
						});
					})
					.catch(() => {

					});
			},
			//插销完成
			CancelTask(event, row) {
				// this.TakingInventoryQueryInfoRow = row;
				// this.TakingInventoryQueryInfoOpenTime = new Date().getTime();
				this.$confirm('确定取消确认完成吗?', this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhInvTaskCompleteCancel + "/" + row.id, {}, data => {
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							// this.dialogShow = false;
							this.initData();
						});
					})
					.catch(() => {
			
					});
			},
			

			//同步至系统库存
			SyncSysStockAction(event, row) {
				// this.TakingInventoryQueryInfoRow = row;
				// this.TakingInventoryQueryInfoOpenTime = new Date().getTime();
				this.$confirm('请慎重操作!!!确定同步至系统库存?', this.$t('cc62f4bf31d661e3'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhInvTaskSyncSysStock + "/" + row.id + "/sync/sysstock", {}, data => {
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							// this.dialogShow = false;
							this.initData();
						});
					})
					.catch(() => {

					});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},


			// //提交签收
			// submitSign() {
			// 	this.postData(this.$urlConfig.WhExceSign + '/' + this.signDate.id, {
			// 		id:  this.signDate.id,
			// 		inWhDate: this.signDate.inWhDate,
			// 		remark: this.signDate.remark
			// 	}, () => {
			// 		this.initData();
			// 		this.dialogSign = false;
			// 		this.$message.success(this.$t('9f30371831a98237'));
			// 	});
			// },


			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>