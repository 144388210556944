<template>
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_28bc604691209657')" append-to-body :visible.sync="dialogShow" :direction="'rtl'"
		size="800px">
		<div>

			<!-- <div> -->
			<el-table :size="'small'" :data="showData" :max-height="$store.state.tableMaxHeight2" style="width: 100%">
				<el-table-column type="index" fixed="left" width="50" align="center"
					:label="$t('7b1c2b1adc920d9c')"></el-table-column>
				<el-table-column prop="taskNo" :label="$t('i18nn_44858bef4ea92a98')"></el-table-column>
				<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>
				<el-table-column prop="inLpn" :label="'ILPN'"></el-table-column>
				<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
				<el-table-column prop="quantity" :label="$t('f9db93b87e08763b')"></el-table-column>
				<el-table-column prop="bckAccount" :label="$t('i18nn_cc9fd9b44e3429ed')"></el-table-column>
			</el-table>
			<!-- </div> -->

		</div>

		<!-- <div class="drawer-footer">
			<el-button type="warning" icon="el-icon-tickets" style="width: 220px;" @click="submitForm()">完成本次任务</el-button>
		</div> -->

	</el-drawer>
</template>
<script>
	// import {
	// 	getDicData
	// } from '@/axios/common.js';

	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	// import FeeModelById from '@/components/WarehouseCenter2/Other/FeeModelById.vue';
	export default {
		components: {
			// FeeModelById
			// whNoSelect
		},
		props: {
			openTime: {},
			// url: {},
			row: {}
		},
		data() {
			return {
				// detTabActiveName: 'first',

				dialogShow: false,

				// printerVal: "",
				// printerList: [],
				//表格数据
				//loading,表格数据
				loading: false,
				// loading_load: false,
				//详情数据
				// loading_det: false,
				// taskId:'',
				showData: [],
				// form: {
				// 	"title":"",
				// 	"whNo":""
				// },

				// formRules: {

				// },
				// selectOption: {
				// 	feetypeList: [],
				// 	wh_op_type: [],
				// },

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				this.dialogShow = true;
				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			//费用类型
			// this.getFeeTypeData();
			//数据字典
			// getDicData(['wh_op_type'],
			// 	(data) => {
			// 		this.selectOption.wh_op_type = data['wh_op_type'];
			// 	});

		},
		methods: {
			initData() {
				this.getQueryData(this.row.id);

				// this.resetForm('form');
				// this.form = {
				// 	"title":"",
				// 	"whNo":""
				// }
			},

			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },

			// addRowData() {
			// 	this.form.recordAmtDtoList.push({
			// 		"feeType": null, //this.$t('46c3f6e0f657e7a3'),
			// 		"feeAmt": null, //this.$t('d7315fb8114eb446')
			// 		"remark": null
			// 	});
			// },
			// delRowData(event, index) {
			// 	event.stopPropagation();
			// 	this.form.recordAmtDtoList.splice(index, 1)
			// },
			//初始化打印机列表
			// initPriterList() {
			// 	let printerList = GetPrinterList();
			// 	console.log('printerList', printerList);
			// 	this.printerList = printerList;
			// },
			//保存选择的打印机
			// changePrinter(v) {
			// 	//保存此次打印机的值
			// 	// savePrtVal(this.printerVal);
			// 	// this.printerVal = v;
			// 	// this.initPagesizesList();
			// },
			// resetForm(formName) {
			// 	console.log(formName);
			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}
			// },

			//提交信息
			// submitForm() {

			// 	this.$confirm('确定完成本次盘点任务吗?', this.$t('cc62f4bf31d661e3'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhInvTaskComplete + "/" + this.row.id, {}, data => {
			// 				this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
			// 				this.dialogShow = false;
			// 			});
			// 		})
			// 		.catch(() => {

			// 		});
					
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						// callback(data.data);
						if (200 == data.code) {
							callback(data.data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			getQueryData(taskId) {
				this.$http
					.put(this.$urlConfig.WhInvTaskQueryInfo, {
						"taskId": taskId, //"任务ID",
						"isOnlyFlag": '0', //"只获取自己提交 1 ; 0-->获取全部"
					})
					.then(({
						data
					}) => {
						if (200 == data.code) {
							// this.selectOption.feetypeList = data.rows;
							this.showData = data.rows;
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>